import React from 'react';
import Forgot from 'src/components/auth/Forgot';
import inBrowser from 'src/utils/inBrowser';
import isLoggedIn from 'src/utils/isLoggedIn';

const ForgotPasswordPage = () => {

    if (inBrowser && isLoggedIn) {
        window.location.href = '/account';
    }

    return (
            <div>
                <Forgot />
            </div>
    );
};

export default ForgotPasswordPage;
